import { Component, Input } from '@angular/core';
import { CmsBlock } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-quote-cms-block',
  templateUrl: './ob-quote-cms-block.component.html',
  styleUrls: ['./ob-quote-cms-block.component.scss'],
})
export class ObQuoteCmsBlockComponent {
  @Input() public data: CmsBlock;
}
