import { Component, Input } from '@angular/core';
import { MarinaTerms } from '@dm-workspace/types';
import { ObMarinaAgreementsForm } from '@dm-workspace/online-booking/ob-shared';

@Component({
  selector: 'dm-ob-quote-marina-agreements-form',
  templateUrl: './ob-quote-marina-agreements-form.component.html',
  styleUrls: ['./ob-quote-marina-agreements-form.component.scss'],
})
export class ObQuoteMarinaAgreementsFormComponent {
  @Input({ required: true }) form: ObMarinaAgreementsForm;
  @Input({ required: true }) terms: MarinaTerms;
}
