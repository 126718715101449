import { Component, Input } from '@angular/core';
import { CmsBlockRichText } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-quote-cms-rich-text',
  templateUrl: './ob-quote-cms-rich-text.component.html',
  styleUrls: ['./ob-quote-cms-rich-text.component.scss'],
})
export class ObQuoteCmsRichTextComponent {
  @Input() public data: CmsBlockRichText;
}
