<dm-ui-confirmation-icon [size]="56" [icon]="entityPaymentStatus | entityPaymentStatusIcon" />
<h2 class="title" [innerHTML]="'PAYMENT_STATUS_MODAL.' + (entityPaymentStatus | uppercase) + '.TITLE' | translate"></h2>
<p
  class="subtitle"
  [innerHTML]="
    'PAYMENT_STATUS_MODAL.' + (entityPaymentStatus | uppercase) + '.DESCRIPTION' | translate: { email: contactEmail }
  "
></p>
<p class="small">
  {{ "ORDER_ID" | translate }}: <b>{{ entityId }}</b>
</p>
<ng-content />
