import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, LOCALE_ID, Output } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EntityPaymentMethods,
  PaymentHistory,
  PaymentProvider,
  TPaymentChannel,
  TResourceBookingPaymentResponse,
} from '@dm-workspace/types';
import { ObOrdersApiService } from '@dm-workspace/data-access';
import { PaymentComponentClass } from './payment.component';
import { CustomerOrderApiService } from '../../../../../data-access/src/libs/orders/customer-orders-api.service';

@Component({
  selector: 'dm-payment-order-entity',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentOrderEntityComponent extends PaymentComponentClass {
  #orderApiService = inject(ObOrdersApiService);
  #customerApiService = inject(CustomerOrderApiService);
  #localId = inject(LOCALE_ID);
  @Input({ required: true }) orderId: string;
  @Input() checkId: string;
  @Input() showReturnButton = false;
  @Output() retryToMainPage = new EventEmitter();
  protected getEntityId() {
    return this.orderId;
  }

  protected getPaymentHistory(): Observable<PaymentHistory | null> {
    return this.checkId
      ? this.#orderApiService.getPaymentHistory(this.orderId, { checkId: this.checkId })
      : this.#customerApiService.getPaymentHistory(this.orderId);
  }

  protected getPaymentMethods(): Observable<EntityPaymentMethods> {
    return this.checkId
      ? this.#orderApiService.getPaymentMethods({
          ...this.getParams(),
          checkId: this.checkId,
        })
      : this.#customerApiService.getPaymentMethods(this.getParams());
  }

  protected createPayment(paymentType: PaymentProvider): Observable<TResourceBookingPaymentResponse> {
    const params = {
      ...this.getParams(),
      paymentType: paymentType,
    };
    return this.checkId ? this.#orderApiService.paymentOrder(params) : this.#customerApiService.paymentOrder(params);
  }
  getParams() {
    return {
      channel: 'Web' as TPaymentChannel,
      shopperLocale: this.#localId,
      orderId: this.orderId,
    };
  }
}
