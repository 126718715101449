@switch (state()) {
  @case ("status") {
    <dm-payment-status
      [entityPaymentStatus]="paymentStatus()"
      [contactEmail]="marina?.additionalProperties?.contactInfo?.email"
      [entityId]="getEntityId()"
    >
      <div class="buttons">
        <button *ngIf="canRetryPayment()" type="button" class="btn btn-primary" (click)="initPayment()">
          {{ "TRY_AGAIN" | translate }}
        </button>
        <button *ngIf="showReturnButton" type="button" class="btn btn-primary-outline" (click)="retryToMainPage.emit()">
          {{ "RETURN_TO_MAIN_PAGE" | translate }}
        </button>
      </div>
    </dm-payment-status>
  }

  @case ("select-provider") {
    <p class="title mb-2">{{ "SELECT_PAYMENT_METHOD" | translate }}</p>
    <dm-payment-select-provider
      [providers]="availablePaymentMethodTypes()"
      (selectProvider)="setPaymentProvider($event)"
    />
  }

  @case ("pay") {
    @if (availablePaymentMethodTypes().length > 1) {
      <div class="d-flex justify-content-between">
        <p class="title mb-2">{{ "SELECTED_PAYMENT_METHOD_" + selectedProvider() | uppercase | translate }}</p>
        <button class="color-primary" type="button" (click)="backToSelectProvider()">
          <i class="i-arrow-left"></i>
          {{ "BACK" | translate }}
        </button>
      </div>
    }

    @if (sessionExpiresAt()) {
      <dm-payment-countdown class="timer" [toDate]="sessionExpiresAt()" (countingDone)="onPaymentSessionExpired()" />
    }

    @switch (selectedProvider()) {
      @case ("Adyen") {
        <dm-payment-method-adyen
          [paymentReferenceId]="referenceId()"
          (paymentSuccess)="onPaymentSuccess()"
          (paymentError)="onPaymentError()"
          (paymentMountError)="onPaymentError($event)"
          (paymentMounted)="onPaymentMount($event)"
        />
      }
      @case ("Iyzipay") {
        <dm-payment-method-iyzipay
          [paymentReferenceId]="referenceId()"
          (paymentMountError)="onPaymentError($event)"
          (paymentMounted)="onPaymentMount($event)"
        />
      }
      @case ("WireTransfer") {
        <dm-payment-method-wire-transfer
          [paymentReferenceId]="referenceId()"
          (paymentMountError)="onPaymentError($event)"
          (paymentMounted)="onPaymentMount($event)"
          (paymentSuccess)="onWireTransferConfirm()"
        />
      }
    }
  }
}
<dm-ui-overflow-spinner type="absolute" [showSpinner]="pending()" />
