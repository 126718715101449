<ng-container [formGroup]="form">
  <dm-form-group class="theme-light mb-3">
    <input type="checkbox" dmForm formControlName="agreementTwo" id="agreementTwo" />
    <label for="agreementTwo">
      <span class="required">*</span> {{ "I_AGREE_WITH" | translate }}
        <a class="link" [href]="terms.servicesRenewals" target="_blank">{{
            "TERMS_CONDITIONS_OF_SERVICES" | translate
          }}</a>
    </label>
    <dm-form-error-message
      [control]="form.controls.agreementTwo"
      required="THE_AGREEMENT_TWO_IS_REQUIRED"
    />
  </dm-form-group>

  <dm-form-group class="theme-light mb-3" *ngIf="form.controls.agreementIt">
    <input type="checkbox" dmForm formControlName="agreementIt" id="agreementIt" />
    <label for="agreementIt">
      <span class="required">*</span> {{ "I_AGREE_WITH" | translate }}
        <a class="link" [href]="terms.vexationClausesRenewals" target="_blank">{{
            "VEXATION_CLAUSES" | translate
          }}</a>
    </label>
    <dm-form-error-message
      [control]="form.controls.agreementIt"
      required="THE_AGREEMENT_THREE_IS_REQUIRED"
    />
  </dm-form-group>
</ng-container>
