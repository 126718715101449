import { computed, Directive, effect, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import {
  EntityPaymentMethods,
  EntityPaymentStatus,
  EntityPaymentStatusEnum,
  Marina,
  PaymentHistory,
  PaymentMethodMount,
  PaymentMethodType,
  TResourceBookingPaymentResponse,
} from '@dm-workspace/types';
import { catchError, finalize, first, map, Observable, tap, throwError } from 'rxjs';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';
import { PaymentHistoryEntityPipe } from '../../pipes/payment-history-entity.pipe';

export type PaymentComponentState = 'status' | 'pay' | 'select-provider';

@Directive()
export abstract class PaymentComponentClass implements OnInit {
  #notificationService = inject(NotificationService);
  @Output() paymentSuccess = new EventEmitter<PaymentMethodType>();
  @Output() paymentWireTransferConfirm = new EventEmitter<void>();
  @Output() paymentStateChange = new EventEmitter<PaymentComponentState>();
  @Input({ required: true }) marina: Marina;

  protected readonly pending = signal<boolean>(true);
  protected readonly state = signal<PaymentComponentState>(null);
  protected readonly selectedProvider = signal<PaymentMethodType>(null);
  protected readonly sessionExpiresAt = signal<Date>(null);
  protected readonly referenceId = signal<string>(null);
  protected readonly paymentStatus = signal<EntityPaymentStatus>(null);
  protected readonly availablePaymentMethodTypes = signal<PaymentMethodType[]>(null);
  protected readonly canRetryPayment = computed(() => {
    return ['Pending', 'Failed', 'Session_Expired'].includes(this.paymentStatus());
  });

  constructor() {
    this.#watchStateChange();
    this.#watchPaymentStatusChange();
  }

  #watchStateChange() {
    effect(() => {
      const state = this.state();
      if (!state) {
        return;
      }
      this.paymentStateChange.emit(state);
    });
  }

  #watchPaymentStatusChange() {
    effect(() => {
      const status = this.paymentStatus();
      switch (status) {
        case 'Success': {
          this.paymentSuccess.emit(this.selectedProvider());
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.#checkPaymentStatus();
  }

  #checkPaymentStatus() {
    this.paymentStatus.set(null);

    this.#getPaymentHistory()
      .pipe(
        tap((paymentStatus) => {
          this.paymentStatus.set(paymentStatus);

          if (!paymentStatus) {
            this.initPayment();
            return;
          }

          this.paymentStatus.set(paymentStatus);
          this.state.set('status');
        })
      )
      .subscribe();
  }

  protected abstract getPaymentHistory(): Observable<PaymentHistory>;

  #getPaymentHistory() {
    this.pending.set(true);

    return this.getPaymentHistory().pipe(
      finalize(() => this.pending.set(false)),
      first(),
      map(PaymentHistoryEntityPipe.getPaymentStatusFromPaymentHistory),
      catchError((res: HttpErrorResponse) => {
        this.paymentStatus.set(EntityPaymentStatusEnum.FAILED);
        this.state.set('status');
        this.#handleErrorNotification(res);
        return throwError(() => res);
      })
    );
  }

  protected initPayment() {
    this.pending.set(true);

    this.getAvailablePaymentTypes()
      .pipe(
        finalize(() => this.pending.set(false)),
        tap((paymentTypes) => {
          this.availablePaymentMethodTypes.set(paymentTypes);

          switch (paymentTypes.length) {
            case null:
            case 0: {
              this.paymentStatus.set(EntityPaymentStatusEnum.NO_PAYMENT);
              this.state.set('status');
              break;
            }
            case 1: {
              const [firstPaymentType] = paymentTypes;
              this.setPaymentProvider(firstPaymentType);
              break;
            }
            default: {
              this.state.set('select-provider');
              break;
            }
          }
        })
      )
      .subscribe();
  }

  protected getAvailablePaymentTypes(): Observable<PaymentMethodType[]> {
    return this.getPaymentMethods().pipe(
      catchError((res: HttpErrorResponse) => {
        this.#handleErrorNotification(res);
        this.paymentStatus.set(EntityPaymentStatusEnum.FAILED);
        this.state.set('status');
        return throwError(() => res);
      }),
      map((paymentMethods) => {
        return paymentMethods.paymentTypes
          .filter((paymentType) => paymentType.isEnabled)
          .map((paymentType) => paymentType.type);
      })
    );
  }

  protected abstract createPayment(paymentType: PaymentMethodType): Observable<TResourceBookingPaymentResponse>;
  protected setPaymentProvider(paymentType: PaymentMethodType) {
    this.pending.set(true);
    this.selectedProvider.set(paymentType);

    this.createPayment(paymentType)
      .pipe(
        finalize(() => this.pending.set(false)),
        catchError((res: HttpErrorResponse) => {
          this.paymentStatus.set(EntityPaymentStatusEnum.FAILED);
          this.state.set('status');
          this.#handleErrorNotification(res);
          return throwError(() => res);
        }),
        tap((val) => {
          this.referenceId.set(val.referenceId);
          this.state.set('pay');
        })
      )
      .subscribe();
  }

  protected backToSelectProvider() {
    this.selectedProvider.set(null);
    this.sessionExpiresAt.set(null);
    this.state.set('select-provider');
  }

  protected onPaymentMount(mountData?: PaymentMethodMount) {
    const { sessionExpiresAt } = mountData ?? {};
    this.pending.set(false);
    this.sessionExpiresAt.set(sessionExpiresAt);
  }

  protected onPaymentSessionExpired() {
    this.sessionExpiresAt.set(null);
    this.paymentStatus.set(EntityPaymentStatusEnum.SESSION_EXPIRED);
    this.state.set('status');
  }

  protected onPaymentError(res?: HttpErrorResponse) {
    this.#handleErrorNotification(res);
    this.paymentStatus.set(EntityPaymentStatusEnum.FAILED);
    this.pending.set(false);
    this.state.set('status');
  }

  protected onPaymentSuccess() {
    this.paymentStatus.set(EntityPaymentStatusEnum.SUCCESS);
    this.state.set('status');
  }

  protected onWireTransferConfirm() {
    this.paymentWireTransferConfirm.emit();
  }

  #handleErrorNotification(res: HttpErrorResponse) {
    const notificationContent = ApiValidator.getApiNotificationError(res);
    this.#notificationService.openError(notificationContent);
  }

  protected abstract getEntityId(): string;

  protected abstract getPaymentMethods(): Observable<EntityPaymentMethods>;
}
