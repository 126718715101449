import { Component, Input } from '@angular/core';
import { CmsBlockImage } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-quote-cms-image',
  templateUrl: './ob-quote-cms-image.component.html',
  styleUrls: ['./ob-quote-cms-image.component.scss'],
})
export class ObQuoteCmsImageComponent {
  @Input() public data: CmsBlockImage;
}
